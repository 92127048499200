.service-providers-block {
  .gray-bg {
    padding: 60px 0px;
    background-color: $gray_l4;

    .service-provider-title {
      margin-bottom: 30px;
    }

    .logo-filter {
      margin-bottom: 60px;

      .clear-btn {
        background-color: transparent;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .filter-wrapper:nth-child(6n),
      .filter-wrapper:last-child {
        .item-logo {
          border-right: 0;
        }
      }

      .filter-wrapper:nth-child(n + 13) {
        .item-logo {
          border-bottom: 0;
        }
      }

      .item-logo {
        border-bottom: 1px solid $gray_l1;
        border-right: 1px solid $gray_l1;

        .car-logo-container {
          height: 100%;
          width: 100%;
          padding: 33px 0;
          position: relative;

          img {
            height: 30px;
            max-width: 67px;
          }

          &.active {
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 3px;
              background-color: $bl_lightblue;
            }
          }
        }
      }
    }

    .provider-card-wrapper {
      margin-bottom: 30px;
      height: 100%;

      .provider-card {
        background-color: $white;
        border-radius: 4px;
        border: 1px solid $gray_l2;
        padding: 25px 20px 20px;

        .info-container {
          .card-title {
            font-size: $h5-font-size;
            font-weight: $font-weight-bold;
            color: $bl_blue;
            margin-bottom: 6px;
          }

          .phone-container {
            margin-bottom: 12px;

            .phone-label {
              font-size: 14px;
              margin-bottom: -7px;
            }

            .phone {
              margin-bottom: 22px;
              font-size: $h5-font-size;
              font-weight: $font-weight-bold;
              color: $bl_blue;
              white-space: nowrap;
            }
          }
        }

        .car-logo {
          .car-logo-container {
            border: 1px solid #ebedef;
            max-width: 103px;
            padding: 10px 0;
            margin-bottom: 10px;
            flex: 1 1 auto;

            img {
              height: 30px;
              max-width: 65.09px;
            }
          }
        }

        .button {
          font-weight: 700;
          text-align: center;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
          line-height: 1.2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          white-space: nowrap;
          width: 100%;

          &.dark-blue {
            background-color: $bl_blue;
          }

          &.double-btn {
            width: calc(50% - 4px);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .service-providers-block {
    padding: 60px 0 40px;

    .gray-bg {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .service-providers-block {
    .logo-filter {
      .filter-wrapper:nth-child(6n) {
        .item-logo {
          border-right: 1px solid $gray_l1 !important;
        }
      }

      .filter-wrapper:nth-child(4n),
      .filter-wrapper:last-child {
        .item-logo {
          border-right: 0 !important;
        }
      }
    }

    .provider-card-wrapper {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .provider-card {
        margin-bottom: 22px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .service-providers-block {
    .gray-bg {
      .provider-card-wrapper {
        .provider-card {
          .info-container {
            .car-logo {
              width: 31% !important;
            }
          }

          .button-container {
            flex-direction: column;

            .button {
              margin-bottom: 10px;
              width: 100%;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .service-providers-block {
    .gray-bg {
      .logo-filter {
        .filter-wrapper:nth-child(6n),
        .filter-wrapper:nth-child(4n) {
          .item-logo {
            border-right: 1px solid $gray_l1 !important;
          }
        }

        .filter-wrapper:nth-child(3n),
        .filter-wrapper:last-child {
          .item-logo {
            border-right: 0 !important;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .service-providers-block {
    .gray-bg {
      .provider-card-wrapper {
        .provider-card {
          height: calc(100% - 30px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

// Specific breakpoints
@include media-breakpoint-only(lg) {
  .service-providers-block {
    padding: 60px 0 45px;
  }
}

@media (min-width: 1200px) and (max-width: 1418px) {
  .service-providers-block {
    .gray-bg {
      .provider-card {
        .button-container {
          .double-button {
            font-size: 14px;
          }
        }
      }
    }
  }
}
