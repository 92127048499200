@import "@styles/custom";

.car-make-nav {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $gray_l1;
  position: relative;

  &__list {
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 4rem;
    padding: 0 1rem;
    margin: 0;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: $gray $gray_l3;

    @include media-breakpoint-down(lg) {
      gap: 2.5rem;
    }

    &__item {
      padding: 1rem 0;
      text-align: center;
      vertical-align: middle;
      display: grid;
      place-items: center;

      &--active {
        border-bottom: 3px solid $secondary;
      }

      & img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .scroll-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    width: 80px;
    transition: opacity 0.1s ease-in-out;
  }

  .left-indicator {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.8), transparent);
    left: 0;
  }

  .right-indicator {
    background: linear-gradient(to left, rgba(255, 255, 255, 0.8), transparent);
    right: 0;
  }
}
