.image-carousel {
  &__wrapper {
    display: grid;
    grid-template-columns: auto fit-content(960px) auto;
    place-content: center;
    place-items: center;
    gap: 0.5rem;
  }

  &__images {
    position: relative;
    width: 100%;
    height: auto;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    visibility: hidden;
    pointer-events: none;
    user-select: none;

    &--visible {
      position: static;
      opacity: 1;
      visibility: visible;
    }
  }

  &__nav {
    color: $gray-d2;
    background: $gray-l3;
    border: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    padding: 0.5rem;

    & > svg {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      color: $gray-d2;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &__thumbnails {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__thumbnail {
    width: 5rem;
    height: 5rem;
    border: 0.25rem solid $gray-l3;
    border-radius: 50%;
    background: transparent;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &--selected {
      border-color: $secondary;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border: 0.125rem solid transparent;
      border-radius: 50%;
    }
  }

  &__progress-bar {
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      flex: 1;
      appearance: none;
      border: none;
      height: 1px;
      background: $gray-l3;
      position: relative;
      margin-right: 8px;
      transition:
        background 0.2s ease-in-out,
        height 0.2s ease-in-out;

      &:last-child {
        margin-right: 0;
      }
      /* Increase the click target size */
      &::before {
        content: "";
        height: 1rem;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &--selected {
        height: 0.25rem;
        background: $secondary;
      }
    }
  }

  &__progress-counter {
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    color: $gray-d2;
  }

  &--compact {
    .image-carousel {
      &__wrapper {
        display: flex;
        grid-template-columns: 1fr;
      }

      &__nav {
        display: none;
      }

      &__thumbnail {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .image-carousel {
    &__nav {
      position: absolute;
      width: auto;
      height: 2.25rem;
      background-color: transparent;
      &.right {
        right: 0;
        padding-right: 0;
        // margin-right: -10px;
      }
      &.left {
        left: 0;
        padding-left: 0;
        // margin-left: -10px;
      }
    }
  }
}
