@import "@styles/custom";

.data-list {
  display: flex;
  flex-direction: column;

  &__title {
    color: $primary;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    margin: 0;
    border-bottom: 1px solid $secondary;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    color: $gray_d2;

    &__item {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem;

      &:nth-child(even) {
        background-color: $gray_l4;
      }

      &__value {
        font-weight: $font-weight-medium;
      }
    }
  }
}
