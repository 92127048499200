/* ---------------------------------
   DO NOT INCLUDE STYLES IN THIS FILE

   This file is for custom variables ONLY.
   Adding styles to this file will result in duplication.
   ----------------------------------
*/

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

/* Colors */
$enable-dark-mode: false;

$bl_blue: #103483;
$bl_blue_d1: #0c2865;
$bl_lightblue: #3bade0;
$bl_lightblue_d1: #339ccb;
$bl_red: #ff4a2e;
$bl_green: #7cb39c;
$bl_orange: #d89368;

$primary: $bl_blue;
$secondary: $bl_lightblue;
$tertiary: $bl_red;

$gray: #a1acb1;
$gray_d1: #616466;
$gray_d2: #3c4043;
$gray_l1: #bec9ce;
$gray_l2: #dbe1e4;
$gray_l3: #e8f0f4;
$gray_l4: #f5f7f8;

// Add the colors to the bootstrap map
$theme-colors: map-merge(
  $theme-colors,
  (
    "primary": $primary,
    "secondary": $secondary,
    "gray-l3": $gray_l3,
  )
);

$links: $bl_lightblue;
$link_hover: $bl_red;

$main_paragraph: #47535d;
$main_label: $gray_d2;

$body-color: $main_paragraph;

$placeholder: #838587;

$modal_overlay: rgba(60, 64, 67, 0.97);
$table_headings: $bl_blue;
$table_text: $main_paragraph;
$switch_background: rgba(190, 201, 206, 0.25);
$gray_l1_15: rgba(190, 201, 206, 0.15);
$lightblue_seethrough: rgba(59, 173, 224, 0.1);
$question-backgroud: rgba(190, 201, 206, 0.15);
$link-color: $main_paragraph;
$link-decoration: none;

/* Fonts */
$font-family-sans-serif: "TT Norms Pro", system-ui, sans-serif;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: bold;
$font-weight-bolder: 800;

$font-size-base: 1rem;
$h1-font-size: clamp(2.5rem, 5vw, 4rem); //40px - 64px
$h2-font-size: clamp(2.25rem, 3vw, 3rem); //36px - 48px
$h3-font-size: clamp(1.875rem, 2.5vw, 2.25rem); //30px - 36px
$h4-font-size: clamp(1.5rem, 2vw, 1.875rem); //24px - 30px
$h5-font-size: clamp(1.25rem, 1.5vw, 1.5rem); //20px - 24px
$h6-font-size: clamp(1.25rem, 1.5vw, 1.375rem); //20px - 22px
$h7-font-size: clamp(1.125rem, 1.5vw, 1.25rem); //18px - 20px
$h8-font-size: clamp(1rem, 1.5vw, 1.125rem); //16px - 18px

$paragraph-large: clamp(1.125rem, 2vw, 1.375rem); //18px - 22px
$paragraph-standard: clamp(1rem, 2.2vw, 1.25rem); //16px - 20px
$paragraph-small: clamp(0.75rem, 1.5vw, 1rem); //12px - 16px
$paragraph-xsmall: clamp(0.75rem, 1.5vw, 0.875rem); //12px - 14px

$title-small: clamp(14px, 1.5vw, 16px);
$pageheader-title: clamp(48px, 3vw, 64px);

$headings-font-weight: 600;
$headings-color: $primary;
$headings-line-height: 1;

/* Spacing */
$block-bottom-spacing: 100px;
$grid-gutter-width: 40px;

$input-border-radius: 0.1875rem;

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 40px;
}

/* box shadow for cards */
$card-shadow: 0px 3.997px 11.992px 0px rgba(0, 0, 0, 0.08);