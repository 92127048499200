.locations-block {
  margin-bottom: 140px;

  .gray-bg {
    background-color: $gray_l4;
  }

  .location-content {
    padding: 60px 0px;

    .location-title {
      padding-bottom: 60px;
    }

    .location-card-wrapper {
      margin-bottom: 40px;
      height: 100%;

      .location-card {
        background-color: $white;
        border-radius: 4px;
        border: 1px solid $gray_l2;
        padding: 38px 20px 22px;

        .card-title {
          margin-bottom: 26px;
          font-size: $h5-font-size;
          font-weight: $font-weight-bold;
          color: $bl_blue;
        }

        .car-logo {
          .car-logo-container {
            border: 1px solid #ebedef;
            max-width: 103px;
            padding: 10px 0;
            margin-bottom: 10px;
            flex: 1 1 auto;

            img {
              height: 30px;
              max-width: 65.09px;
            }
          }
        }

        .phone {
          margin-bottom: 22px;
          font-size: $h5-font-size;
          font-weight: $font-weight-bold;
          color: $bl_blue;
          white-space: nowrap;
        }

        .button {
          font-weight: 700;
          text-align: center;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
          line-height: 1.2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          // width: calc(50% - 4px);
          width: 100%;
          white-space: nowrap;

          &.dark-blue {
            background-color: $bl_blue;
          }
        }
        
        .address-info {
          .opening-hours {
            p {
              margin-bottom: 0;
            } 
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .locations-block {
    padding: 60px 0 40px;

    .location-content {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .locations-block {
    .location-card-wrapper {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .location-card {
        margin-bottom: 22px;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .locations-block {
    padding: 60px 0 45px;
  }
}

@include media-breakpoint-down(md) {
  .locations-block {
    .location-content {
      .location-card {
        .car-logo {
          width: 31% !important;
        }
        .button-container {
          flex-direction: column;

          .button {
            margin-bottom: 10px;
            width: 100%;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .locations-block {
    .location-content {
      .location-card-wrapper {
        .location-card {
          height: calc(100% - 40px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1418px) {
  .locations-block {
    .location-content {
      .location-card {
        .button-container {
          .button {
            font-size: 14px;
          }
        }
      }
    }
  }
}
