.team-expertise-block {
  .grey-start {
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: calc(100vw - 99%);
      left: calc((100% - 100vw));
      z-index: -1;
      display: block;
      background-color: #f5f5f5;
    }
  }

  .employees-container {
    background-color: #f5f5f5;
    margin-right: 40px;
    padding-right: 75px;
    margin-left: -15px;
    padding-left: 30px;
    padding-top: 60px;
    padding-bottom: 45px;

    .employee-container-title {
      font-size: $h5-font-size;
      font-weight: $font-weight-bold;
      color: $bl-blue;
      margin-bottom: 30px;
    }

    .employee-card {
      background-color: $white;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
      margin-bottom: 30px;

      .img-container {
        padding: 25px 30px 25px 20px;
      }

      .name {
        font-size: $h6-font-size;
      }
      .department {
        font-size: 15px;
        font-weight: $font-weight-bold;
      }
      .name,
      .phone {
        font-weight: $font-weight-bold;
        color: $bl-blue;
      }

      .position {
        font-weight: $font-weight-bold;
        margin-bottom: 40px;
      }

      .email {
        color: $bl-lightblue;
      }

      .position,
      .email,
      .phone {
        font-size: 16px;
      }
    }
  }

  .text-container {
    .title {
      font-size: $h2-font-size;
      margin-bottom: 25px;
    }

    .paragraph {
      font-size: 18px;
    }
  }
}

@include media-breakpoint-down(md) {
  .team-expertise-block {
    margin-bottom: 100px;

    .img-container {
      order: 0;
    }

    .employee-text-container {
      padding-right: 25px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .team-expertise-block {
    .employees-container {
      padding-right: 25px;
      margin-right: 25px;
      margin-bottom: 65px;

      .employee-card {
        .employee-text-container {
          padding-right: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .team-expertise-block {
    .employees-container {
      padding-right: 25px;
      margin-right: 0;
      margin-bottom: 65px;
      margin-left: unset;
    }
  }
}

@include media-breakpoint-down(md) {
  .team-expertise-block {
    .employees-container {
      padding-left: 15px;
      padding-right: 15px;

      .employee-card {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
