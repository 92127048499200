.paragraph-block {
  margin-bottom: 100px;

  h2 {
    font-weight: $font-weight-bold;
    color: $bl_blue;
  }

  h3 {
    font-weight: $font-weight-bold;
    color: $bl_blue;
  }

  h4 {
    font-weight: $font-weight-bold;
    color: $bl_blue;
    margin-bottom: 1rem;
  }

  p {
    font-weight: $font-weight-normal;
    a {
      color: $bl_lightblue;
    }
    b {
      font-weight: $font-weight-bold;
    }
    i {
      font-style: italic;
    }
    a {
      color: $bl_lightblue;
      text-decoration: none;
      transition: color 0.3s ease-in-out;

      &:hover {
        text-decoration: none;
        color: $bl_red;
      }
    }
  }
  ul {
    li {
      font-size: $paragraph-standard;
    }
  }

  img {
    height: auto;
    width: 100%;

    &.full-width {
      margin: 40px 0;
      max-height: 495px;
      object-fit: cover;
      width: 100%;
    }

    &.left {
      float: left;
      margin: 25px 30px 50px 0;
      max-height: 300px;
      width: auto;
    }

    &.right {
      float: right;
      margin: 25px 0 50px 30px;
      max-height: 300px;
      width: auto;
    }
  }

  &.qa-paragraph-block {
    margin-bottom: unset;

    p {
      font-size: 18px !important;
    }
  }
}
@include media-breakpoint-down(md) {
  .paragraph-block {
    img {
      &.left,
      &.right {
        max-height: 180px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .paragraph-block {
    img {
      &.left,
      &.right {
        float: unset;
        margin: 20px 0;
        max-height: unset;
        max-width: 100%;
      }
    }
  }
}
