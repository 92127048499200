.booking-form-index-page {
  margin-top: 40px;
  .text-container {
    margin-bottom: 80px;
  }
  a {
    .booking-option {
      border: 1px solid $gray_l1;
      padding: 60px 30px;
      margin-bottom: 40px;
      height: calc(100% - 40px);
      transition: box-shadow 0.3s ease-in-out;


      .icon-container {
        margin-bottom: 14px;
        height: 100%;
      }
      .option-title {
        font-size: 15px;
        font-weight: $font-weight-bold;
      }
    }
    &:hover {
      .booking-option {
        color: $main_paragraph;
        box-shadow: $card-shadow
      }
    }
  }
}
