.toggle {
  width: fit-content;
  background-color: transparent;
  border: 1px solid rgb(161, 172, 177, 0.25);
  border-radius: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__switch {
    width: 50%;
    height: 100%;
    background-color: $bl_blue;
    border-radius: 1rem;
    transition: transform 0.3s ease-in-out;
    position: absolute;
    left: 0;
  }

  &__labels-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    height: 32px;
  }

  &__label {
    flex: 1 1 0;
    width: 50%;
    min-width: max-content;
    height: 100%;
    padding: 0.25rem 1rem;
    transition: color 0.3s ease-in-out;
    text-align: center;
    font-weight: 400;
    color: #313E3E;
    user-select: none;
    z-index: 1;

    &:first-child {
      color: $white;
      font-weight: 700;
    }
  }

  &.toggle--active {
    .toggle {
      &__switch {
        transform: translateX(100%);
      }

      &__label {
        &:first-child {
          color: #313E3E;
          font-weight: 400;
        }

        &:last-child {
          color: $white;
          font-weight: 700;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .toggle {
    &__label {
      font-size: 14px;
    }
  }
}
@include media-breakpoint-down(sm) {
  .toggle {
    &__label {
      font-size: 14px;
      padding: 6px;
    }
  }
}