.staff-card-block {
  .gray-bg {
    border-radius: 4px;
    padding-top: 90px;
    padding-bottom: 70px;

    h3.title {
      margin-bottom: 60px;
    }
    .staff-card {
      background-color: $white;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
      border-radius: 3px;
      padding: 20px 20px;
      height: calc(100% - 50px);
      margin-bottom: 50px;
      .image-container {
        margin-right: 20px;
        .placeholder {
          background-color: #F8F9FA; 
          width: 146px;
          height: 188px ;
        }
      }
      .text-container {
        padding-top: 16px;
        .department {
          color: #62676c;
          font-size: 14px;
          margin-bottom: 10px;
        }
        .name {
          color: $bl_blue;
          font-size: $h7-font-size;
          font-weight: $font-weight-bold;
        }
        .position-title {
          color: #62676c;
          font-size: 18px;
          font-weight: $font-weight-medium;
        }
        .contact {
          margin-top: auto;
          margin-bottom: 20px;
          .email {
            color: $bl_lightblue;
          }
          .phone {
            font-size: 18px;
            color: $bl_blue;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .staff-card-block {
    .gray-bg {
      padding-top: 70px;
      padding-bottom: 50px;
      .staff-card {
        padding: 20px 12px 15px;
        height: calc(100% - 30px);
        margin-bottom: 30px;
      }
    }
  }
}
