.ReactModal {
  &__Overlay {
    background-color: $modal_overlay !important;
    z-index: $zindex-modal-backdrop;
  }
  .ReactModal__Content {
    background-color: $white !important;
    overflow: scroll !important;
  }  

  &__Content {
    max-width: 820px;
    height: fit-content;
    max-height: 96vh;
    z-index: 100;
    padding: 68px 90px !important;
    margin: auto;
    width: fit-content;
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.25);
    border-radius: 0 !important;
    z-index: $zindex-modal;
    background-color: $white !important;
    overflow: scroll !important;

    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100svh;
      margin: 0px;
      border: none;
      position: static !important;
    }

    @include media-breakpoint-down(md) {
      padding: 64px 48px !important;
    }

    @include media-breakpoint-down(sm) {
      padding: 24px 16px !important;
    }

    .modal-description {
      padding-bottom: 2rem;

      .modal-title {
        margin-bottom: 18px;
      }
    }

    .inner-content {
      padding: 40px;
      border: 1px solid $gray_l1;
      border-radius: 4px;
      display: flex;
      flex-direction: column;

      .input-lable {
        font-size: 14px;
        margin-bottom: 8px;
        font-weight: 500;
      }

      .input {
        margin-bottom: 21px;
        border: 1px solid $gray_l1;
        border-radius: 2px;
        height: 50px;
      }
    }
  }

  .inner-content {
    padding: 40px;
    border: 1px solid $gray_l1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .input-lable {
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: 500;
    }

    .input {
      margin-bottom: 21px;
      border: 1px solid $gray_l1;
      border-radius: 2px;
      height: 50px;
    }
  }
}
