.car-image-gallery {
  p {
    color: $primary;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__carousel {
    min-height: 16rem;

    @include media-breakpoint-up(lg) {
      min-height: 18rem;
    }

    @include media-breakpoint-up(xl) {
      min-height: 20rem;
    }
  }

  &__swatches {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    place-content: center;
    place-items: center;
    gap: 0.5rem;
    margin: 0.5rem auto;
  }

  &__swatch {
    width: 2.1875rem;
    height: 2.1875rem;
    padding: 0.25rem;
    background: transparent;
    border: 0.125rem solid transparent;
    border-radius: 50%;
    display: grid;
    place-content: center;
    transition: border-color 0.3s ease-in-out;
    &:hover {
      border-color: $gray_l1;
    }

    @include media-breakpoint-up(md) {
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }

    &--selected {
      border-color: $secondary;
      &:hover {
        border-color: $secondary !important;
      }
    }
  }

  &__swatch-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;
    .image-carousel__thumbnail {
      transition: border-color 0.3s ease-in-out;
      &:not(.image-carousel__thumbnail--selected) {
        &:hover {
          border-color: $gray_l1;
        }
      }
    }
  }


  &__thumbnails__group {
    margin: 0 auto;
    display: grid;
    grid-template-rows: repeat(2, auto);
  }
}
