.single-faq-question {
  .questions-container {
    cursor: pointer;
    border-radius: 4px;
    height: 130px;
    background-color: $question-backgroud;
    padding: 51px 42px;
    margin-bottom: 20px;
    transition: linear 0.2s;

    &.expanded {
      background-color: $white;
      box-shadow: 0 10px 80px 0 rgba(0, 0, 0, 0.15);
      height: fit-content;

      .plus-icon {
        svg {
          transform: rotate(45deg);
        }
      }
    }

    .question {
      padding-bottom: 71px;
    }
  }

  .plus-icon {
    svg {
      height: 28px;
      width: 28px;
      transition: linear 0.2s;
    }
  }

  .question {
    font-size: 24px;
    line-height: 26px;

    &.expanded {
      color: $bl_blue;
    }
  }
}
