.service-benefits-block {
  margin-bottom: 160px;

  .gray-bg {
    border-radius: 16px;
    padding: 40px 0px;

    .benefit-card {
      border: 1px solid $bl-lightblue;
      border-radius: 16px;
      padding: 60px 50px 50px;
      height: 280px;
      background-color: $white;

      .icon-container {
        width: 80px;
        margin-bottom: 30px;
      }

      .description {
        font-size: $h6-font-size;
        font-weight: $font-weight-medium;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
