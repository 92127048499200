@import "@styles/custom";

.loading-spinner {
  color: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1rem;
  min-height: 1rem;
  width: 3rem;
  aspect-ratio: 1;

  &__spinner {
    border: 0.25rem solid currentColor;
    border-top: 0.25rem solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    animation: spin 1s linear infinite;
  }
}
