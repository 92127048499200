.info-card-block {
  .block-title-container--title {
    &::before {
      content: none;
    }
  }

  &--card {
    border: 1px solid $gray;
    border-radius: 16px;
    height: 100%;
    padding: 40px 30px 38px;

    &.no-number {
      padding: 40px 30px 38px;
    }

    .number-container {
      width: fit-content;
    }

    h5 {
      font-size: $h5-font-size;
      word-wrap: break-word;
    }

    .underline {
      width: 51px;
      background-color: $bl_lightblue;
      height: 4px;
      text-decoration: underline;
      display: block;
      margin: 14px 0 33px;
    }

    p {
      font-size: $h8-font-size;
      a {
        font-weight: $font-weight-bold;
        color: $bl_lightblue;
        position: relative;
        transition: color 0.3s ease-in-out;


        &::after {
          content: "→";
          right: -7%;
          top: 0;
          position: absolute;
          width: 0%;
          transition: right 0.3s ease-in-out, color 0.3s ease-in-out;
        }

        &:hover {
          color: $bl_blue;
          &::after {
            right: -10%;
            color: $bl_blue;
          }
        }
      }
    }
  }
}

@include media-breakpoint-between(xl, md) {
  .info-card-block {
    .info-card-col {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0 !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .info-card-block {
    .info-card-col {
      &:last-child() {
        margin-bottom: 0 !important;
      }
    }
  }
}
