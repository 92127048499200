.news-index-page {
  margin-bottom: 180px;

  .page-title-container {
    .page-title {
      font-size: $h4-font-size;
    }
  }

  .news-articles {
    :nth-child(3n + 2) {
      position: relative;
      top: 70px;
    }

    .news-item {
      transition: box-shadow 0.3s ease-in-out;
      margin-bottom: 50px;

      &-image {
        aspect-ratio: 1.17;
        overflow: hidden;
        object-fit: cover;

        img {
          min-height: 410px;
          object-fit: cover;
          aspect-ratio: 1.17;
        }
      }

      &-content {
        background-color: $white;
        padding: 24px 16px 11px;
        z-index: 1;
        bottom: 0;
        right: 0;
        width: 80%;
        top: unset !important;

        .news-item-date {
          font-size: 12px;
          font-weight: $font-weight-bold;
          color: $bl-lightblue;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        .news-item-title {
          font-size: $h6-font-size;
          font-weight: $font-weight-bold;
          top: unset;
        }
      }
      &:hover {
        box-shadow: $card-shadow;
      }
    }

    .button {
      margin-top: 30px;
      width: 100%;
    }
  }
}

// Page title container on both news index and news page
.news-index-page,
.news-page {
  .page-title-container {
    margin: 60px 0 80px;

    .page-title {
      font-size: $h4-font-size;
    }
  }
}

@include media-breakpoint-down(sm) {
  .news-index-page,
  .news-page {
    .page-title-container {
      margin: 30px 0 80px;
    }
  }
}

@include media-breakpoint-down(md) {
  .news-index-page {
    margin-bottom: 100px;

    .news-articles {
      .news-item {
        &-content {
          padding: 15px 18px 0px;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .news-index-page {
    margin-bottom: 100px;

    .news-articles {
      margin-bottom: 40px;

      .button {
        margin-bottom: 0 !important;
        margin-top: 30px !important;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .news-index-page {
    .news-articles {
      :nth-child(3n + 2) {
        top: unset;
      }

      :nth-last-child(2) {
        margin-bottom: unset;
      }
    }
  }
}
