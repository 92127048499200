.slide-block-car {
  padding-bottom: 32px;

  .img-container {
    order: unset !important;
    max-height: 570px;
    aspect-ratio: 1.5;
    display: flex;

    .img-fluid {
      object-fit: contain;
    }

    &.has-image {
      img {
        border-radius: 8px 0 0 8px;
      }
    }
  }

  .slide-content {
    position: relative;
    opacity: 0;
    transition: opacity 0.3s linear;

    .pre-title {
      font-size: $h5-font-size;
      color: $bl_lightblue;
      font-weight: $font-weight-medium;
      font-size: $h5-font-size;
    }

    a {
      white-space: nowrap;
      color: $bl_lightblue;
      font-weight: $font-weight-bold;
      font-size: $h7-font-size;
      transition: color 0.3s ease-in-out;

      svg {
        margin-left: 13px;
        transition: margin-left 0.6s ease;

        g g {
          transition: fill 0.3s ease-in-out;
          fill: $bl_lightblue;
        }
      }

      &:hover {
        color: $bl_blue;

        svg {
          margin-left: 15px;

          g g {
            fill: $bl_blue;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .slide-block-car {
    .img-container {
      align-items: center;
      display: flex;
        &.has-image {
          img {
            border-radius: unset;
          }
        }
    }

    .slide-content {
      height: 500px;
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .slide-block-car {
    .slide-content {
      height: 450px;
    }
  }
}

@include media-breakpoint-down(md) {
  .slide-block-car {
    margin-bottom: 0;

    .img-container {
      margin-bottom: -60px;
      align-items: center;
      display: flex;
      justify-content: center;
      &.has-image {
        margin-bottom: unset;
      }
    }

    .slide-content {
      padding-left: unset;
      height: 300px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .slide-block-car {
    .slide-content {
      height: 450px;
      padding: 0 20px;
    }

    .slide-img {
      padding: 0 !important;
    }
  }
}