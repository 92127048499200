.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.font-extrabold {
  font-weight: 800;
}

// Regular weight
@font-face {
  font-family: "TT Norms Pro";
  src: url("../fonts/ttnorms-regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

// Medium weight
@font-face {
  font-family: "TT Norms Pro";
  src: url("../fonts/ttnorms-medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}

// Bold weight
@font-face {
  font-family: "TT Norms Pro";
  src: url("../fonts/ttnormspro-bold.woff2") format("woff2");
  font-style: normal;
  font-weight: bold;
}

// Bold weight Italic style
@font-face {
  font-family: "TT Norms Pro";
  src: url("../fonts/ttnormspro-bolditalic.woff2") format("woff2");
  font-style: italic;
  font-weight: bold;
}

// Extra bold weight
@font-face {
  font-family: "TT Norms Pro";
  src: url("../fonts/ttnormspro-extrabold.woff2") format("woff2");
  font-style: normal;
  font-weight: 800;
}

// Extra bold weight italic style
@font-face {
  font-family: "TT Norms Pro";
  src: url("../fonts/ttnormspro-extrabolditalic.woff2") format("woff2");
  font-style: italic;
  font-weight: 800;
}
