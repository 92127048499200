body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  main {
    min-height: 90vh;
  }
}

/* Media query to maintain compatibility with bootstraps .container class */
@include media-breakpoint-up(lg) {
  .small-clamped {
    max-width: 1024px !important;
  }
}

@include media-breakpoint-up(xl) {
  .clamped {
    max-width: 1560px !important;
  }

  .medium-clamped {
    max-width: 1600px !important;
  }

  .wide-clamped {
    max-width: 1740px !important;
  }

  .header-clamped {
    max-width: 1840px !important;
  }
}

p {
  font-size: $paragraph-standard;

  // Pre title in blocks
  &.title-small {
    font-size: $title-small;
    font-weight: $font-weight-bold;
    color: $bl-lightblue;
    text-transform: uppercase;
  }
}

.text-large {
  font-size: $paragraph-large;
}

.text-small {
  font-size: $paragraph-small !important;
}

.text-xsmall {
  font-size: $paragraph-xsmall;
}

.text-strikethrough {
  text-decoration: line-through;
}

// Gray background on blocks
.gray-bg {
  background-color: $gray_l1_15;
}

.mb-50 {
  margin-bottom: 50px;
}

// margin bottom between blocks on frontpage.Also used for bigger spacing between.
.mb-180 {
  margin-bottom: 180px;

  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }
}

// Margin-bottom on blocks (Smaller space)
.mb-150 {
  margin-bottom: 150px;

  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }
}

.pt-80 {
  padding-top: 80px;
  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }
}

/* https://getbootstrap.com/docs/4.6/layout/grid/#no-gutters */
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.scroll-area {
  overflow: auto;
}

.themed-scroller {
  scrollbar-color: $secondary $gray_l3;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 1rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray_l3;
    border-radius: 1rem;
  }
}

.w-15 {
  width: 15%;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.min-w-0 {
  min-width: 0;
}

@for $i from 0 through 5 {
  .min-w-#{$i} {
    min-width: $spacer * $i * 3;
  }
}

//Titles in blocks with blue line to the left
//Important: Copy block-title-container div. For example info_card_block.html
.block-title-container {
  &--title {
    &::before {
      height: 1px;
      width: 41px;
      background-color: $bl_lightblue;
      display: block;
      position: absolute;
      content: "";
      left: -60px;
      top: 50%;
    }
  }
}

// Generate responsive grid classes
@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    @for $i from 1 through 12 {
      .grid-cols-#{$breakpoint}-#{$i} {
        grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
      }
    }
  }
}

.gap-2rem {
  gap: 2rem;
}

.rental-summary-link {
  color: $bl_lightblue;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
    color: $bl_red;
  }
}

.filter-ordering {
  order: unset;
  @include media-breakpoint-down(xl){
    order: 1;
    margin-bottom: 8px; 
  }
}

.energy-grant-disclaimer {
  a {
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
    &:hover {
      color: $bl_lightblue;
      border-bottom: 2px solid $bl_lightblue;
    }
  }
}