.car-comparison-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 18rem;
  padding: 0.5rem;
  border: 1px solid $gray_l3;
  height: 100%;

  &__image {
    height: 100%;
    width: auto;
    object-fit: contain;
  }

  &__title {
    color: $primary;
    font-size: $h8-font-size;
    font-weight: $font_weight_bold;
    margin: 0;
  }

  &__remove {
    padding: 0.75rem !important;
    
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
