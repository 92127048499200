// Styles escpecially for form page and windshield form are at the bottom of the file
form {
  .fields {
    .form-description {
      font-size: $h8-font-size;
      margin-bottom: 25px;
    }

    .field-container {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      label {
        font-size: 15px;
        font-weight: $font-weight-bold;

        &.form-check-label {
          font-size: $h8-font-size;
        }

        &.cause-label {
          font-weight: $font-weight-normal;
        }

        &.form-label {
          text-transform: uppercase;
          margin-bottom: 0;
          font-size: 14px;
        }
      }

      input,
      textarea,
      select {
        margin-top: 7px;
        padding: 10px;
        border: 1px solid $gray_l2;
        border-radius: 3px;

        &:focus {
          outline: none;
        }

        &:not([type="checkbox"]):not([type="radio"]) {
          min-height: 50px;
        }
      }

      input[type="checkbox"] {
        position: relative;
        appearance: none;
        cursor: pointer;
        height: 14px;
        margin: 0 10px 0 0;
        border: 1px solid $gray_l1;
        height: 24px;
        width: 24px;

        &:checked {
          background-color: transparent !important;

          &::before {
            content: "";
            position: absolute;
            left: 8px;
            top: 3px;
            display: inline-block;
            transform: rotate(45deg);
            height: 14px;
            width: 7px;
            border-bottom: 3px solid $bl_lightblue;
            border-right: 3px solid $bl_lightblue;
            z-index: 10;
          }
        }
      }

      input[type="radio"] {
        position: relative;
        appearance: none;
        cursor: pointer;
        height: 14px;
        margin: 0 10px 0 0;
        border: 1px solid $gray_l1;
        height: 30px;
        width: 30px;
        border-radius: 50%;

        &:checked {
          background-color: transparent !important;

          &::before {
            content: "";
            position: absolute;
            top: 6.5px;
            left: 6.5px;
            display: inline-block;
            background-color: $bl_lightblue;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            z-index: 10;
          }
        }
      }
    }

    input.form-control.date::-webkit-calendar-picker-indicator {
      background: transparent;
      width: 30px;
      height: 30px;
      z-index: 1;
    }

    input.form-control.date {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 12px;
        top: 13px;
        width: 25px;
        height: 23px;
        background: url('data:image/svg+xml,<svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Tímapöntun-Rúðuskipti" transform="translate(-1422, -784)" fill="%233BADE0" fill-rule="nonzero"><path d="M1430,784 L1430,786 L1439,786 L1439,784 L1442,784 L1442,786 L1445,786 C1446.10457,786 1447,786.895431 1447,788 L1447,805 C1447,806.104569 1446.10457,807 1445,807 L1424,807 C1422.89543,807 1422,806.104569 1422,805 L1422,788 C1422,786.895431 1422.89543,786 1424,786 L1427,786 L1427,784 L1430,784 Z M1427,787 L1424,787 C1423.44772,787 1423,787.447715 1423,788 L1423,805 C1423,805.552285 1423.44772,806 1424,806 L1445,806 C1445.55228,806 1446,805.552285 1446,805 L1446,788 C1446,787.447715 1445.55228,787 1445,787 L1442,787 L1442,789 L1439,789 L1439,787 L1430,787 L1430,789 L1427,789 L1427,787 Z M1430,799 L1430,802 L1427,802 L1427,799 L1430,799 Z M1436,799 L1436,802 L1433,802 L1433,799 L1436,799 Z M1442,799 L1442,802 L1439,802 L1439,799 L1442,799 Z M1430,793 L1430,796 L1427,796 L1427,793 L1430,793 Z M1436,793 L1436,796 L1433,796 L1433,793 L1436,793 Z M1442,793 L1442,796 L1439,796 L1439,793 L1442,793 Z" id="Date-picker-icon"></path></g></g></svg>');
      }
    }

    input.form-control.file-input:not(.has-data) {
      color: transparent;
    }

    input::file-selector-button {
      visibility: hidden;
    }

    .file-input {
      position: relative;

      &::before {
        content: "Sækja mynd";
        position: absolute;
        display: inline-block;
        height: 100%;
        padding: 16px 22px;
        top: 0;
        right: 0;
        background-color: $bl_lightblue;
        color: white;
        border-radius: 0 3px 3px 0;
        border: none;
        font-weight: $font-weight-bold;
        font-size: $h8-font-size;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;

      .button {
        width: 220px;
        &.disabled,
        &[disabled] {
          background-color: $gray_l1 !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  form {
    .fields {
      .cause-wrapper {
        justify-content: space-between;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  form {
    .fields {
      .form-description {
        font-size: $h8-font-size;
        margin-bottom: 20px;
      }
    }
  }
}

// Title and description head for booking forms
.form-head {
  h1 {
    word-wrap: break-word;
  }

  .description {
    background-color: $gray_l4;
    padding: 14px 20px;

    p {
      font-size: $h8-font-size;
      margin-bottom: 0;
    }
  }

  .header-line {
    border-bottom: 1px solid $gray_l2;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 4px;
      width: 50px;
      background-color: $bl_lightblue;
      bottom: 0;
      left: 0;
    }
  }
}

// Styles only for windshield form block
.windshield-form-block {
  form {
    .fields {
      .submit {
        .button {
          background-color: $bl_blue;
        }
      }
    }
  }
}

// Styles only for form page
.form-page {
  h1 {
    margin-bottom: 50px;
    margin-top: 40px;

    @include media-breakpoint-down(md) {
      margin-top: 0px;
    }
  }

  form {
    ul.fields {
      li {
        flex-direction: column;

        label {
          &:has(input[type="radio"]) {
            display: flex;
            align-items: center;
            margin-right: 20px;
          }

          &:has(input[type="checkbox"]) {
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 10px;
            min-width: 125px;
          }
        }

        div {
          &:has(> div label input[type="radio"]) {
            display: flex;
            align-items: center;
          }

          &:has(> div label input[type="checkbox"]) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }
        }
      }

      .submit {
        .button {
          background-color: $bl_lightblue;
        }
      }
    }
  }
}

.form-label-sm {
  font-size: 0.875rem;
}
