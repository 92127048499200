@import "~bootstrap/scss/mixins";

.card-grid {
  padding-top: 2rem;
  /* There is an issue with footer not being included in virtual scroller */
  /* So we need to add padding to the bottom of the grid */
  padding-bottom: 10rem;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    /* All rows HAVE TO be the same height for virtual scroller to work */
    grid-auto-rows: 34rem;
    gap: 2rem;
    justify-content: center;
    padding: 2rem 0;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
      grid-auto-rows: 36rem;
    }
  }

  &__item {
    animation: fadeIn 0.15s ease-out;
    animation-fill-mode: both;
    display: flex;
    justify-content: center;
  }

  &__loading-indicator {
    padding: 3rem;
    display: grid;
    place-items: center;
  }
}

@include media-breakpoint-down(md) {
  .card-grid {
    &__grid {
      gap: 3rem;
    }
  }
}
