@import "@styles/custom";

.accordion {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__item {
    border: 1px solid $gray_l2;
    border-radius: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin: 0;
  }

  &__trigger {
    appearance: none;
    border: none;
    background: none;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  &__content {
    min-height: 0;
    overflow: hidden;

    &[data-state="open"] {
      animation: accordion-down 0.2s ease-out;
    }

    &[data-state="closed"] {
      animation: accordion-up 0.2s ease-out;
    }

    &__inner {
      padding: 1rem;
    }
  }
}

@keyframes accordion-down {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-up {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}
