@import "~rc-slider/assets/index";

.slider {
  // So that the handles do not overflow the container
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__text-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    p {
      font-size: 0.875rem;
    }

    .slider__price {
      text-wrap: nowrap;
    }
  }

  .rc-slider {
    min-width: 200px;
    display: flex;

    .rc-slider-track {
      background-color: $bl_blue;
      height: 4px;
    }

    .rc-slider-rail {
      background-color: $bl_blue;
      z-index: -1;
      height: 4px;
    }

    .rc-slider-handle {
      background-color: $bl_blue;
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
      opacity: 1;
      height: 22px;
      width: 22px;
      top: 0px;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border: none;
      border-color: transparent;
      box-shadow: none;
      box-shadow: 0 2px 8px 0 rgba(208, 75, 75, 0.12);
    }
  }
}
