.button-tab-block {
  margin-bottom: 50px;
  .button-tabs {
    margin-bottom: 110px;
    .nav-item {
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .button,
    .nav-link {
      height: 55px;
      padding: 11px 22px;
      color: $gray_d2;
      border: 1px solid $gray_l1;
      font-size: $h8-font-size;
      font-weight: $font-weight-medium;

      &.active {
        color: $white;
        background-color: $bl_lightblue;
      }
    }
  }
}
