.header-section {
  background-color: $gray-l1_15;
  margin-bottom: 140px;
  position: relative;
  padding-right: 20px;
  min-height: 564px;

  &.no-img{
    background-color: transparent;
    padding-right: 20px;
    min-height: unset;
    margin-top: 75px;
    margin-bottom: 90px;

    .text-container {
      padding-left: unset;
      padding-right: unset;
    }

    h1.title {
      font-size: clamp(48px, 3vw, 56px);
    }
  }

  img {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
  }

  .single-img-container {
    object-fit: cover;

    .single-img {
      object-fit: cover;
      bottom: 40px;
      position: relative;
      height: calc(100% + 40px);
      right: -20px;
      stroke: white;
      stroke-width: 1px;
    }
  }

  .double-img {
    
    .img-left {
      object-fit: cover;
      position: relative;
      top: 40px;
    }

    img {
      width: 100%;
    }

    .img-right {
      object-fit: cover;
      position: relative;
      top: -40px;
    }
  }

  .text-container {
    padding: 0 80px;

    h1.title {
      font-size: $pageheader-title;
    }

    .subtitle {
      font-weight: $font-weight-medium;
      font-size: $h5-font-size;
      color: $bl_lightblue;
    }

    .button {
      padding: 17px 53px;
      width: fit-content;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .header-section {
    .double-img {
      img {
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .header-section {
    .double-img {
      img {
        height: 100%;
      }
    }
    .text-container {
      padding: 0 50px;
      padding-top: 40px;

    }
  }
}

@include media-breakpoint-down(lg) {
  .header-section {
    padding-bottom: 22px;

    .text-container {
      padding-left: 0;

      .intro {
        padding-bottom: 0;
      }
    }

    .single-img-container {
      .single-img {
        width: 100%;
        height: 100%;
        bottom: unset;
        right: unset;
      }
    }
    &.no-img {
      .pageheader-col {
        justify-content: start !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header-section {
    .single-img-container {
      .single-img {
        height: 100%;
        bottom: unset;
      }
    }

    .text-container {
      .button {
        font-size: 16px;
        padding: 15px 40px;
        white-space: nowrap;
      }
    }
    .double-img{
      padding: 0;
      .img-left {
        padding-right: 10px;
        padding-left: 20px;
      }
      .img-right {
        padding-left: 10px;
        padding-right: 20px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .header-section {
    .button {
      font-size: 14px;
      padding: 14px 38px;
    }

    .text-container {
      padding-left: 80px;
    }

    
    h1 {
      max-width: 250px;
      overflow-wrap: break-word;
    }
  }
  h2 {
    max-width: 250px;
    overflow-wrap: break-word;
  }

}

@include media-breakpoint-up(lg) {
  .header-section {
    .single-img-container {
      padding-right: 0;
    }

    .button {
      margin-bottom: 20px;
    }
  }
}
