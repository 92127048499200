.service-highlight-block {
  .gray-bg {
    padding: 63px 0px;
    border-radius: 16px;
    min-height: 300px;

    h3 {
      margin-bottom: 20px;
    }

    .service-highlight-subtitle {
      margin-bottom: 20px;
    }

    .service-highlight-description {
      font-size: $h6-font-size;
    }

    .image {
      position: relative;

      img {
        position: absolute;
        bottom: -150px;
        height: 200px;
      }
    }

    li {
      list-style: none;
      position: relative;
      margin-bottom: 20px;
      font-weight: $font-weight-medium;
      font-size: $paragraph-standard;

      svg {
        min-width: 24px;
        margin-right: 10px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .service-highlight-block {
    .gray-bg {
      padding: 48px 30px;
      margin-bottom: 120px;

      flex-direction: column;

      .image {
        display: flex;
        justify-content: center;

        img {
          position: relative;
          bottom: unset;
        }
      }
    }
  }
}
