@import "@styles/custom";

.dropdown {
  &.multi {
    min-width: 10rem;
  }

  .dropdown-select {
    &__dropdown-indicator {
      transition: transform 0.2s ease;

      svg {
        fill: $placeholder;
      }
    }

    &__control {
      background-color: #fff;
      border: none;
      border-radius: 4px;
      box-shadow: none;
      cursor: pointer;

      &--menu-is-open {
        .dropdown-select__placeholder {
          color: $main_label;
        }

        .dropdown-select__dropdown-indicator {
          transform: rotate(180deg);
          color: $secondary;
        }
      }
    }

    &__multi-value {
      background-color: $gray_l4;

      &__remove:hover {
        background-color: $gray_l3;
        color: $black;
      }
    }

    &__value-container {
      flex-wrap: nowrap !important;
      overflow-x: auto;
    }

    &__multi-value {
      min-width: 3rem;
    }

    &__placeholder {
      color: $placeholder;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
    }

    &__menu {
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
      z-index: 10;

      .button {
        width: 100%;
        height: auto;
        font-size: 14px;
        border-radius: 0 0 4px 4px;
      }
    }

    &__menu-list {
      scrollbar-color: $secondary #fff;
    }

    &__single-value {
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
    }

    &__option {
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      padding: 0.5rem 0.75rem;
      background-color: #fff;
      color: var(--bs-body-color);

      &.multi {
        justify-content: start;
      }

      &.single {
        text-align: center;
        justify-content: center;
      }

      &:hover {
        background-color: $gray_l4;
      }

      .checkbox {
        height: 16px;
        width: 16px;
        border-radius: 2px;
        border: 1px solid $gray_l1;

        .checkmark {
          display: none;
        }

        &.checked {
          border: 2px solid $bl_lightblue;
          position: relative;

          .checkmark {
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
