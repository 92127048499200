.job-application-block {
  .title {
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    color: $bl_blue;
    margin-bottom: 20px;
  }

  .w-30 {
    width: 30% !important;
  }
  a.button {
    white-space: nowrap;
    width: 30%;
  }
}

@include media-breakpoint-down(md) {
  .job-application-block {
    a.button {
      width: 40%;
    }
  }
}
@include media-breakpoint-down(sm) {
  .job-application-block {
    a.button {
      width: 60%;
    }
  }
}
