.car-details {
  display: grid;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 400px;
    gap: 4rem;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr 480px;
    gap: 5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-bottom: 2rem;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
  }

  &__make {
    color: $secondary;
    font-size: clamp(1.25rem, 2vw, 1.75rem);
    font-weight: $font-weight-medium;
    white-space: break-spaces;
    word-break: keep-all;
  }

  &__model {
    color: $primary;
    font-weight: $font-weight-bold;
    white-space: break-spaces;
    word-break: keep-all;
    font-size: clamp(2.5rem, 5vw, 3.125rem);
  }

  &__comparison-toggle {
    width: max-content;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: $font-weight-medium;
    color: $gray_d2;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include media-breakpoint-down(md) {
      gap: 0.5rem;
    }

    input[type="checkbox"] {
      color: $secondary;
      appearance: none;
      cursor: pointer;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      background-origin: border-box;
      user-select: none;
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      border: 1px solid $gray_l1;
      border-radius: 0.25em;

      @include media-breakpoint-up(sm) {
        width: 1.5rem;
        height: 1.5rem;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
      }

      &:checked {
        border-color: transparent;
        background-color: currentColor;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
      }
    }
  }

  .accordion__item {
    &[data-state="open"] {
      border-color: $bl_lightblue !important;
    }
  }

  &__variant-select {
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.08);

    p {
      margin: 0;
    }

    &__info {
      display: grid;
      grid-template: auto auto / 1fr auto;
      align-items: baseline;
      gap: 0 1rem;
    }

    &__label {
      font-size: 0.75rem;

      &.kjarabill {
        color: $bl_red !important;
      }
    }

    &__accordion {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__header {
        color: $gray_d1;
        font-weight: $font-weight-medium;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color 0.2s;

        &__title {
          margin: 0;
          font-size: clamp(16px, 2vw, 20px);
          font-weight: $font-weight-bold;
          text-wrap: nowrap;
        }

        &__price {
          margin: 0;
          font-size: 14px;
          font-weight: $font-weight-bold;
        }

        &[data-state="open"] {
          color: $primary;
        }
      }
    }

    &__price {
      justify-self: end; /* Right-justify items in the second column */

      font-size: clamp(1.5rem, 2.5vw, 2.25rem);

      span {
        font-size: clamp(1rem, 2vw, 1.25rem);
      }

      &.discount {
        position: relative;
        font-size: clamp(1.2rem, 2.0vw, 1.8rem);

        &::after {
          content: '';
          position: absolute;
          width: calc(100% + 8px);
          height: 4px;
          background-color: rgba(59, 173, 224, 0.8);
          top: 50%;
          transform: translateY(-50%);
          z-index: -1;
          right: -4px;
        }
      }

      &.kjarabill {
        color: $bl_red !important;
      }
    }

    &__price-small {
      font-size: 1rem;

      span {
        font-weight: $font-weight-medium;
        font-size: $paragraph-standard;
      }
    }
    .btn-section {
      .kjarabill-btn {
        background-color: $bl_red;
      }
    }
    &__discounts {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &--compact {
      position: fixed;
      width: 100vw;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      z-index: 100;
      background-color: $white;
      box-shadow:
        0 0 12px 0 rgba(0, 0, 0, 0.08),
        0 0 80px 0 rgba(0, 0, 0, 0.08);

      .tabs__nav {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 0;

        &__tab {
          transition:
            background-color 0.2s ease-in-out,
            border 0.2s ease-in-out,
            box-shadow 0.2s ease-in-out;
          background: #ebedef;

          &--active {
            background-color: #fff;
          }

          &__button {
            font-size: 0.875rem;
            padding: 1.25rem 1rem;
          }
        }
      }
    }

    &--no-rental {
      .tabs {
        border-top: 6px solid $secondary;

        &__nav {
          display: none;
        }
      }
    }
  }

  &__booking {
    width: 100% !important;
    padding: 1.5rem;
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.08);

    .trial-booking {
      font-size: $h8-font-size;
    }

    .dropdown .dropdown-select__placeholder,
    label {
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: $gray_d2;
      text-transform: uppercase;
    }

    .btn-close {
      border: none;
      background-color: transparent;
      padding-left: 20px;
      cursor: pointer;

      svg {
        width: 12px;
      }
    }
  }

  &__section {
    &__title-wrapper {
      margin-bottom: 2rem;
      padding: 0.5rem 0;
      border-bottom: 1px solid $secondary;
    }

    &__title {
      font-weight: $font-weight-bold;
    }
  }

  &__specs-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(xl) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      &__label {
        color: $primary;
        font-size: 0.75rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }

      &__container {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        align-items: baseline;
      }

      &__value {
        color: $gray_d2;
        font-size: $h6-font-size;
      }

      &__unit {
        color: $gray_d2;
        font-size: $h8-font-size;
      }
    }

    @include media-breakpoint-up(lg) {
      &__item+&__item {
        border-left: 1px solid $gray_l2;
      }
    }
  }

  &__attribute-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;

    &__category {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;

      &__name {
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        color: $primary;
        margin: 0;
      }

      &__attributes {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
        gap: 0.375rem 3rem;
        margin: 0;
        padding: 0 0 0 1rem;
        list-style: checkmark;

        &__attribute {
          color: $gray_d2;

          &::marker {
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: $secondary;
            font-weight: 900;
          }
        }
      }
    }
  }

  &__property-list {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: 4rem;
  }

  &__disclaimer {
    background-color: $lightblue_seethrough;
    padding: 16px 20px;
    border-radius: 8px;

    p {
      font-size: 14px;
    }
  }

  span.car-registration-no {
    border: #3bade0 1px solid;
    border-radius: 3px;
    padding: 3px 5px;
    margin-top: -3px;
  }
}

.kjarabill {
  color: $bl_red !important;
}