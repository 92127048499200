.question-list {
  .title {
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    color: $bl_blue;
    margin-bottom: 20px;
  }

  &.question-group {
    .inner-title {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
      color: $bl_blue;
      margin-bottom: 30px;
    }
  }
}
