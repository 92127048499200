footer {
  background-color: $gray_l4;
  padding: 60px 0 62px;

  .logo-container {
    svg {
      min-height: 57px;
    }
  }

  .tab-content {
    .tab-pane.fade{
      transition: opacity 0.3s ease-in-out;
    }
  }

  ul {
    li {
      margin: 8px 30px 8px 0;
      .nav-link {
        display: inline-block;
        align-items: center;
        font-size: 20px;
        color: $main_label;
        padding: 0;
        position: relative;
        flex-direction: column;
        transition: color 0.3s ease-in-out, font-weight 0.1s ease-in-out;


        &::after {
          content: attr(data-text);
          display: block;
          font-size: 20px;
          font-weight: 700;
          opacity: 0;
          height: 0;
          pointer-events: none;
          white-space: nowrap;
          border-bottom: 2px solid transparent;
          overflow: hidden;
          visibility: hidden;
        }

        &.active {
          color: $bl_blue;
          font-weight: 700;
          border-bottom: 2px solid $bl_lightblue;
        }
        
        &:hover {
          color: $bl_blue;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }
    &:first-of-type {
      margin-left: 0;
    }
  }

  .tab-content {
    .starfsstod-img {
      z-index: 1;
      position: absolute;
      padding-left: 0;


      img {
        max-height: 287px;
        max-width: 610px;
        object-fit: cover;
      }
    }

    .starfsstod-content {
      background-color: $white;
      min-height: 376px;
      padding: 40px 60px 40px 100px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 40px;

      p {
        font-size: 16px;
        color: $main_label;
        margin-bottom: 0;

        b {
          font-weight: $font-weight-medium;
        }
      }

      .oh-title,
      .phone,
      .email,
      .address {
        color: $bl_blue;
        font-weight: 700;
        font-size: $font-size-base;
      }
      .opening-hours{
        p {
          font-size: clamp(14px, 1.2vw, 16px);
        }
      }
      .phone, .email {
        transition: color 0.3s ease-in-out;

        &:hover{
          color: $bl_lightblue;
        }
      }

      .phone {
        font-size: 24px;
      }

      .bilaland-text {
        h3 {
          color: $bl_blue;
          font-weight: 700;
          font-size: $font-size-base;
        }
        p {
          margin-bottom: 16px;
        }
      }
    }
  }

  .social-section {
    border-top: 1px solid $gray_l2;
    margin-top: 80px;
    padding-top: 33px;

    a {
      margin-right: 26px;

      &:last-of-type {
        margin-right: 0;
      }
      svg {
        .fill-color { 
          transition: fill 0.3s ease-in-out; 
        }
        &:hover {
          .fill-color {
            fill: #8a9397;
          }
        }
      }  
    }
    .privacy-policy {
      position: relative;
      top: 30px;

      a {
        margin-right: unset;
        transition: color 0.3s;
        color: $gray_d1;
      }
    }
  }
}


@media (min-width: 1200px) and (max-width: 1370px){
  footer {
    .tab-content {
      .starfsstod-content {
        margin-left: 25px;
      }
    }
  }
}


@include media-breakpoint-down(xl) {
  footer {
    .tab-content {
      .starfsstod-content {
        padding: 40px 25px 40px 70px;
        margin-left: 0px;

        p {
          white-space: normal;
        }
      }
    }
  }
}


@include media-breakpoint-down(lg) {
  footer {
    .tab-content {
      .starfsstod-img {
        z-index: 0;
        position: relative;
        padding: 0;

        img {
          max-height: unset;
          max-width: unset;
        }
      }

      .starfsstod-content {
        left: unset;
        padding: 40px 60px 40px;
        position: relative;
        z-index: 1;
        top: -40px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1055px){
  footer {
    .tab-content {
      .starfsstod-content {
        padding: 40px 11px 40px 60px;
      }
    }
  }
}



@include media-breakpoint-down(md) {
  footer {
    ul {
      justify-content: center;

      li {
        margin: 8px;
        .nav-link {
          font-size: 1rem;
          background-color: $white;
          padding: 11px;
          border-radius: 4px;
          width: 170px;
          text-align: center;

          &.active {
            background-color: $bl_lightblue;
            font-weight: 700;
            color: $white;
            border-bottom: none;
          }
        }
      }
    }

    .tab-content {
      .starfsstod-content {
        padding: 40px 41px 60px;
      }
    }
    .social-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .privacy-policy {
        top: unset;
        display: flex;
        justify-self: center;
        order: 2;
        margin-top: 30px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  footer {
    .tab-content {
      .starfsstod-content {
        padding: 32px 41px 60px;
      }
    }
  }
}