.news-list-block {
  .title {
    margin-bottom: 50px;
  }

  .news-list-item {
    transition: box-shadow 0.3s ease-in-out;
    &-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;

      img {
        min-height: 410px;
        object-fit: cover;
      }
    }

    &-content {
      background-color: $white;
      padding: 24px 16px 11px;
      z-index: 1;
      bottom: 0;
      right: 0;
      width: 80%;

      .news-list-item-date {
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $bl-lightblue;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      .news-title {
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
      }
    }
    &:hover {
      box-shadow: $card-shadow;
    }
  }
}

@include media-breakpoint-down(lg) {
  .news-list-block {
    .news-list-item {
      margin-bottom: 50px;
    }
  }
}
