.card__product {
  width: 350px;
  min-height: 450px;

  .card-img-top {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .card-body {
    display: flex;
    flex-direction: column;

    .card-text-small {
      font-size: 12px;
      color: $bl_lightblue;
      margin-bottom: 8px;
      line-height: 13px;
    }

    .card-text-medium {
      font-size: 15px;
      line-height: 22px;
    }

    .card-text-large {
      font-size: 20px;
      line-height: 24px;
      color: $bl_blue;
    }

    .price-container {
      margin-top: auto;

      .text-disabled {
        color: $gray;
      }
    }
  }
}
