.car-list-block {
  width: 100%;
  min-height: 32rem;
  overflow: hidden;
  scrollbar-color: $bl-lightblue $gray_l3;
  scrollbar-width: thin;

  .car-list {
    display: flex;
    flex-direction: column;
    background-color: $gray_l4;
    padding: 2rem;

    @include media-breakpoint-up(md) {
      border-radius: 1rem 0 0 1rem;
      padding: 3rem 0 3rem 6rem;
    }
  }

  .car-carousel-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -2rem;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }
}
.filter-section {
  .toggler {
    margin-bottom: 0;

    &.advanced-search {
      width: fit-content;
      width: 101px;
      text-align: center;
      border: 1px solid $bl_lightblue;
      border-radius: 4px;
      color: $bl_blue;
      height: 40px !important;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $bl_blue;
        color: $white;
        border: 1px solid $bl_blue;
      }

      .toggler__label {
        padding: 0.656rem 1.031rem;
        font-weight: 700;
      }
      &.toggler--active, &.filter-active {
        background-color: $bl_blue;
        color: $white;
        border: 1px solid $bl_blue;
      }
    }
  }

  .dropdown {
    .dropdown-select {
      &__control {
        border: 1px solid #EBEDEF;
      }

      &__placeholder {
        color: $gray_d2;
      }

      &__dropdown-indicator {
        svg {
          fill: $bl_blue;
        }
      }
    }

    &.no-border {
      .dropdown-select {
        &__control {
          border: none !important;
        }
      }
    }
  }
}
.filter-section-desktop {
  .category-filters {
    button {
      color: $gray_d2 !important;
    }
  }
  @include media-breakpoint-up(xl) {
    .toggler--active.advanced-search {
      position: relative;
      background-color: $bl_blue;
      color: $white;
    }
  }
}

.filter-section-mobile {

  .filter-overlay,
  .mobile-advanced-search {
    padding-top: 20px;
    padding-bottom: 50px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: $white;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mobile-advanced-search {
    padding-top: 70px;
  }

  .dropdown {
    .dropdown-select {
      &__control {
        height: 50px;
        padding: 0px 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .filter-section-desktop {

    .toggler {
      &.advanced-search {
        width: 100% !important;
      }
    }

    .button--kjarabilar {
      width: 100% !important;
    }
  }

  .filter-section-mobile {
    .filter-overlay {
      padding-bottom: 100px;

      .mobile-advanced-search {
        padding-bottom: 100px;
      }
    }
  }
}

.filter-clear-button-label { 
  font-size: 0.75rem; 
  cursor:pointer;
  align-content:center;
}