.pill {
  border-radius: 1rem;
  background-color: $white;
  padding: 4px 8px;
  border: 1px solid $gray_l1;

  &--blue {
    border-color: $bl_lightblue;
    color: $bl_lightblue;
  }

  &--red {
    border-color: $bl_red;
    color: $bl_red;
  }

  &--green {
    border-color: $bl_green;
    color: $bl_green;
  }

  &--orange {
    border-color: $bl_orange;
    color: $bl_orange;
  }

  &--red.color-inverted {
    background-color: $bl_red;
    color: white;
  }

  &--blue.color-inverted {
    background-color: $bl_lightblue;
    color: white;    
  }

  &--green.color-inverted {
    background-color: $bl_green;
    color: white;
  }

  &--orange.color-inverted {
    background-color: $bl_orange;
    color: white;    
  }


  &__label {
    font-size: 12px;
  }
}
