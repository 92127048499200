.image-gallery-block {
  .carousel {
    overflow: hidden;
    margin-bottom: 40px;

    .gallery-bg {
      &:before {
        content: "";
        position: absolute;
        top: -47px;
        left: -4%;
        width: 119%;
        height: 100%;
        background: $gray_l1_15;
      }
    }

    .carousel-inner {
      .carousel-item {
        img {
          margin-bottom: 10px;
        }
        .caption {
          min-height: 24px;
          font-weight: $font-weight-normal;
        }
      }
    }
  }

  .carousel-indicators {
    &.img-indicator {
      bottom: unset !important;
      margin-right: unset !important;
      left: unset !important;
      right: -112px !important;
      flex-direction: column;
      position: absolute !important;
      margin-left: unset !important;

      button {
        width: unset !important;
        height: unset !important;
        text-indent: unset !important;
        background-color: transparent;
        opacity: 1 !important;

        img {
          background-color: transparent;
          border: 2.4px solid $white;
          border-radius: 50%;
          transition: outline 0.1s ease-in-out;
        }

        &.active {
          img {
            border: 2.4px solid $white;
            outline: 2.4px solid $bl_lightblue;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .image-gallery-block {
    .carousel {
      div.carousel-indicators {
        &.img-indicator {
          right: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .image-gallery-block {
    .carousel {
      padding: 2rem 2rem 140px;

      .carousel-inner {
        margin-bottom: 20px;

        .carousel-indicators {
          height: fit-content;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .image-gallery-block {
    .carousel {
      padding: 2rem 2rem 160px;

      .gray-bg {
        &:before {
          height: 122%;
        }
      }

      .carousel-indicators {
        bottom: -48px !important;
        height: fit-content;

        &.img-indicator {
          flex-direction: row;
          bottom: -100px !important;
          left: 0 !important;
          margin-right: 15% !important;
        }
      }
    }
  }
}