// Question and answers block + Job application block
.accordion-container {
  display: block;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: linear 0.2s;

  ul {
    li {
      padding: 25px 42px;
      background-color: $question-backgroud;
      border-radius: 4px;
      margin-bottom: 20px;
      list-style-type: none;
      transition:
        max-height,
        box-shadow 0.3s ease-out;

      .text-container {
        min-height: 63px;
        padding-right: 50px;

        p {
          line-height: 26px;
          font-size: $h6-font-size;
          font-weight: 500;
          color: $bl_blue;
        }

        .plus-icon {
          position: relative;
          width: 28px;
          height: 28px;
          transform-origin: 50% 50%;
          transition: transform 0.2s linear;
          transform: rotate(0deg);
          right: 0;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.5px;
            height: 28px;
            background-color: $bl_blue;
            transform-origin: 50% 50%;
            transition: transform 0.2s linear;
          }

          span:first-child {
            transform: translate(-50%, -50%) rotate(90deg);
          }

          span:last-child {
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }

        &.open .plus-icon {
          transform: rotate(-45deg); 
        }

      }

      .accordion-inner {
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;

        p {
          font-size: 18px;
        }
      }

      &.open {
        max-height: 3000px;
        transition:
          max-height,
          box-shadow 0.3s ease;
        background-color: $white;
        box-shadow: 0 10px 80px 0 rgba(0, 0, 0, 0.16);

        .text-container {
          .plus-icon {
            transition: transform 0.2s linear;
            transform: rotate(-45deg) translate(0, 0);
          }
        }

        .answer {
          max-height: 3000px;
          display: block;
          visibility: visible;
          opacity: 1;
          padding-top: 50px;

          .paragraph-block {
            padding-left: 0;
          }

          p {
            display: block;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .accordion-container {
    ul {
      li {
        .text-container {
          .plus-icon {
            right: -13px;
          }
        }
      }
    }
  }
}