@use "sass:color";

@import "@styles/custom";

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  --tab-separator: none;
  --tab-spacing: 0;
  --tab-justification: space-evenly;
  --tab-flex: 1;
  --tab-color: #{$gray_d2};
  --tab-background: #{rgba($gray_d2, 0.1)};
  --tab-font-size: 1.125rem;
  --tab-padding: 1rem 2rem;
  --tab-active-indicator-height: 6px;
  --tab-active-background: transparent;
  --tab-disabled-color: #{$gray_d2};
  --tab-disabled-background: rgba(#{$gray_d2}, 0.15);

  &--compact {
    --tab-separator: 1px solid #{$gray_l1};
    --tab-spacing: 1rem;
    --tab-justification: flex-start;
    --tab-flex: 0;
    --tab-color: #{$gray_d2};
    --tab-background: transparent;
    --tab-font-size: 1rem;
    --tab-padding: 0.75rem 1rem;
    --tab-active-indicator-height: -4px;
    --tab-active-background: transparent;
    --tab-disabled-color: #{$gray_d2};
    --tab-disabled-background: transparent;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    border-bottom: var(--tab-separator);

    &__tablist {
      flex: 1;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: var(--tab-justification);
      gap: var(--tab-spacing);
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__tab {
      flex: var(--tab-flex);
      color: var(--tab-color);
      background: var(--tab-background);
      transition:
        color 0.2s ease-in-out,
        background 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
      min-width: fit-content;

      &:hover:not([aria-disabled="true"]) {
        color: $primary;
      }

      &--active {
        color: $primary;
        background: var(--tab-active-background);
        box-shadow: inset 0 var(--tab-active-indicator-height) 0 0 $secondary;
      }

      &__button {
        color: inherit;
        font-size: var(--tab-font-size);
        font-weight: $font-weight-bold;
        background: none;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        padding: var(--tab-padding);

        &[disabled] {
          cursor: not-allowed;
          color: var(--tab-disabled-color);
          background: var(--tab-disabled-background);
        }
      }
    }
  }
}
