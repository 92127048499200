
.toggler {
  border: 1px solid $gray_l1;
  background: $white;
  position: relative;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 100%;

  &--active {
    background-color: $gray_l3;
    border: 1px solid $bl_lightblue;
  }

  &__label {
    padding: 1rem 2rem;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $font-weight-medium;
    white-space: nowrap;
  }

  &__delete {
    position: absolute;
    right: 2px;
    transform: translateY(-50%);
    color: $white;
    height: 1rem;
    width: 1rem;
    font-size: 0.625rem;
    border-radius: 50%;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@include media-breakpoint-down(md) {
  .toggler {
    margin-bottom: 20px;
  }
}
