.car-comparison-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;

  .clear-comparison {
    color: $gray_d2;
  }

  .comparison-btn {
    font-size: $h8-font-size;
  }
}
