.slides-block {
  position: relative;

  // Slide background
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 82%;
    height: calc(95% - 20px);
    background: linear-gradient(208.02deg, #ebeef0 0%, #f5f7f8 100%, #f5f7f8 100%);
    border-radius: 16px 0 0 16px;
    z-index: -1;
    max-width: 1400px;
    max-height: 570px;
  }

  .carousel-inner {
    margin-bottom: 20px;
  }

  .carousel-indicators {
    bottom: -25px;

    // Animated color for active slide indicator 
    button {
      max-width: 90px !important;
      width: 100%;
      border: none;
      margin-right: 40px !important;
      position: relative;
      padding: 10px 0;
      background-color: transparent !important;
      opacity: 1;

      .slide-btn {
        width: 100%;
        height: 2px !important;
        position: relative;
        background-color: $gray_l2 !important;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 0%;
          background-color: $bl_lightblue;
          transition: width 5.4s linear;
        }
      }

      &.first-load {
        &:after {
          content: "";
          position: absolute;
          top: 10.4px;
          left: 0;
          height: 2px;
          width: 100%;
          z-index: -1;
          background-color: $gray_l2 !important;
        }
      }

      &:last-child {
        margin-right: 0 !important;
      }

      &.active {
        .slide-btn {
          &::before {
            width: 100%;
            background-color: $bl_lightblue;
          }
        }
      }

      &:not(.active) {
        .slide-btn {
          &::before {
            background-color: $gray_l2 !important;
          }
        }
      }
    }
  }

  // Text on slide doesn't slide out, dissappears and reappears on next
  .carousel-item-end .slide-content,
  .carousel-item-start .slide-content {
    opacity: 0 !important;
    transition: opacity 0s linear !important;
  }

  .carousel-item {

    &.carousel-item-end,
    &.carousel-item-start {
      .slide-content {
        opacity: 0;
      }
    }

    &.active {
      .slide-block-car {
        .slide-content {
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-down(1740px) {
  .slides-block {
    #slidesIndicators {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .slides-block {
    .carousel-inner {
      margin-bottom: 40px;
    }

    .carousel-indicators {
      button {
        margin-right: 20px !important;
      }
    }

    &:after {
      justify-self: center;
      left: 0;
      right: 0;
      width: 100%;
      z-index: -1;
      height: calc(65% - -20px);
      top: unset;
      bottom: 44px;
      border-radius: 0;
    }

    #slidesIndicators {
      padding-right: 20px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .slides-block {
    .carousel-inner {
      margin-bottom: 20px;
    }

    &:after {
      width: 100%;
      height: 75%;
      bottom: 35px;
    }

    #slidesIndicators {
      padding-right: 20px;
    }
  }
}
@media (min-width: 1740px) {
  .slides-block {
    position: relative;
    &::after {
      border-radius: 16px;
    }
  
  }
}