.paragraph-image-block {
  margin-top: 28px;

  .single-img-container {
    .single-img {
      // padding-right: 75px;
      // margin-left: -15px;
      // padding-left: 30px;
    }

    &.order-1 {
      .single-img {
        // padding-left: 75px;
        // margin-right: -15px;
        // padding-right: 30px;
      }
    }
  }

  .double-img {
    img {
      width: 100%;
    }

    .img-2 {
      object-fit: cover;
    }

    .img-left {
      position: relative;
      top: 28px;
    }

    .img-right {
      position: relative;
      top: -28px;
    }
  }

  .text-container {
    .title {
      margin-bottom: 25px;
    }

    .pre-title {
      margin-bottom: 20px;
    }

    .subtitle {
      color: $bl-lightblue;
      margin-bottom: 10px;
    }

    img.richtext-image {
      width: 16.66%;
      height: auto;
    }

    .paragraph-text {
      margin-bottom: 34px;
    }

    a.link {
      color: $bl_lightblue;
      font-weight: $font-weight-bold;
      font-size: $h6-font-size;
      transition: color 0.3s ease-in-out;


      svg {
        margin-left: 13px;
        transition: margin-left 0.3s ease;
      }

      &:hover {
        color: $bl_blue;
        svg {
          margin-left: 15px;
          g g {
            fill: $bl_blue;
          }
        }
      }
    }

    .button {
      min-width: 220px;
      max-width: fit-content;
      font-size: $paragraph-standard;
      white-space: nowrap;

      &.btn-darkblue {
        color: $white;
        background-color: $bl_blue;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: $bl_lightblue;
        }
      }

      &.btn-outlined {
        background-color: transparent;
        border: 1px solid $bl_lightblue;
        border-radius: 3px;
        color: $gray_d2;
        transition: border-color 0.3s ease-in-out;

        &:hover {
          background-color: transparent;
          border-color: $bl_lightblue_d1;
        }
      }
    }
  }

  .gray-bg {
    .single-img-container {
      object-fit: cover;

      .single-img {
        height: 107%;
        object-fit: cover;
        position: relative;
        margin: 50px 0 50px -15px;
      }

      &.order-1 {
        .single-img {
          margin: 50px -15px 50px 0;
        }
      }
    }

    .text-container {
      padding: 40px 0 20px;
    }
  }
}

@include media-breakpoint-down(md) {

  .paragraph-image-block {
    margin-bottom: $block-bottom-spacing;
    margin-top: 0;

    .text-container {
      .button {
        min-width: 190px;
      }
    }

    .gray-bg {
      .text-container {
        padding: 30px 0 15px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .paragraph-image-block {
    .single-img-container {
      .single-img {
        padding-right: unset;
        margin-left: unset;
        padding-left: unset;
      }

      &.order-1 {
        .single-img {
          padding-left: unset;
          margin-right: unset;
          padding-right: unset;
        }
      }

      &:not(.order-1) {
        margin-bottom: 30px;
      }
    }

    .gray-bg {
      padding: unset;

      .single-img-container {
        .single-img {
          padding-right: unset;
          margin-left: unset;
          padding-left: unset;
        }

        &.order-1 {
          .single-img {
            padding-left: unset;
            margin-right: unset;
            padding-right: unset;
          }
        }
        &:not(.order-1) {
          margin-top: 30px;
          margin-bottom: unset;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .paragraph-image-block {
    .single-img-container {
      .single-img {
        padding-right: unset;
        margin-left: unset;
        padding-left: unset;
      }

      &.order-1 {
        margin-bottom: 30px;
        .single-img {
          padding-right: unset;
          margin-left: unset;
          padding-left: unset;
        }
      }
    }

    .gray-bg {
      padding: unset;

      .single-img-container {
        .single-img {
          padding-right: unset !important;
          margin-left: unset;
          padding-left: unset;
          margin: unset
        }

        &.order-1 {
          .single-img {
            padding-left: unset !important;
            margin-right: unset;
            padding-right: unset;
            margin: unset;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .paragraph-image-block {
    .gray-bg {
      .single-img-container {
        .single-img {
          padding-right: 30px;
        }

        &.order-1 {
          .single-img {
            padding-left: 30px;
          }
        }
      }
    }
  }
}
