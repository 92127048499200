@import "~bootstrap/scss/mixins";
@import "@styles/custom";

%button-base {
  appearance: none;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
}

%button-nav {
  color: $secondary;
}

%month-subgrid {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
}

/* Calendar root */
.calendar {
  width: 100%;
  overflow: auto;
  padding: 1rem;
  border: 1px solid $gray_l2;
  border-radius: 3px;
  text-align: center;

  /* Parent for nav and month grid */
  &__months {
    display: grid;
    grid-template: auto auto / 1fr;
    place-items: center;
    position: relative;
  }

  /* Nav with buttons for changing months */
  &__nav {
    position: absolute;
    top: 1.125rem;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  &__button {
    /* Prev month button */
    &-prev {
      @extend %button-base;
      @extend %button-nav;
    }

    /* Next month button */
    &-next {
      @extend %button-base;
      @extend %button-nav;
    }
  }

  &__chevron {
    width: 1rem;
    height: auto;
  }

  /* Current month (with caption and weekday labels) */
  &__month {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    /* Month caption (January 2024 etc.) */
    &__caption {
      /* Span with text */
      &__label {
        color: $primary;
        font-size: 1.5rem;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
      }
    }

    /* Table element for month grid */
    &__grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 0.5rem;

      & > * {
        @extend %month-subgrid;
      }
    }
  }

  /* Table row with weekday labels */
  &__weekdays {
    @extend %month-subgrid;
  }

  /* Table cell with weekday label */
  &__weekday {
    color: $gray_d2;
    font-size: 0.875rem;
    font-weight: $font-weight-base;
  }

  /* Table body with weeks and days in current month */
  &__weeks {
    @extend %month-subgrid;
    gap: 0.5rem;
  }

  /* Table row with one week */
  &__week {
    @extend %month-subgrid;
  }

  /* Table cell with one day */
  &__day {
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1 / 1;
    border: 1px solid transparent;
    border-radius: 2px;
    font-weight: $font-weight-medium;
    color: $gray_d2;
    display: grid;
    place-items: center;

    @include media-breakpoint-up(sm) {
      width: 2.5rem;
      height: 2.5rem;
    }

    /* Class added when the day is today */
    &--today {
      border: 1px solid $secondary;
    }

    /* Class added when the day should be disabled */
    &--disabled {
      color: $gray_d1;
      background: rgba($gray_l1, 0.25);
    }

    /* Class added when the day should be hidden */
    &--hidden {
      visibility: hidden;
    }

    /* Class added when the day is outside the current month */
    &--outside {
      opacity: 0.75;
    }

    /* Class added when the day is selected */
    &--selected {
      background-color: $secondary;
      color: $white;
    }

    /* Button for selecting the day */
    &__button {
      @extend %button-base;
      width: 100%;
      height: 100%;
    }
  }
}
