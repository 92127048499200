.button {
  // font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  border: 0;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    &.white {
      filter: brightness(0) invert(1);
    }
  }

  &--primary {
    color: $white;
    background-color: $bl_lightblue;
    height: 50px;
    padding: 11px 22px;
    transition: background-color 0.3s ease-in-out;

    svg {
      filter: brightness(0) invert(1);
    }

    &:hover {
      background-color: $bl_lightblue_d1;
    }
  }

  &--secondary {
    color: #333;
    background-color: transparent;
    box-shadow: $secondary 0px 0px 0px 1px inset;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $bl_lightblue;
      box-shadow: $bl_lightblue_d1 0px 0px 0px 1px inset;
    }

    &.bl-blue {
      background-color: $bl_blue;
      color: $white;
      box-shadow: none;

      &:hover {
        background-color: $bl_blue_d1;
        transition: background-color 0.3s ease-in-out;
      }
    }
  }

  &--no-border {
    border: 0;
    box-shadow: none;

    &.button--secondary {
      &:hover {
        box-shadow: none !important;
      }
    }
  }

  &--underline {
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    // box-shadow: 0 -2px 0 0 $bl_lightblue inset;

    &::before {
      content: '';
      width: 100%;
      background-color: $bl_lightblue;
      height: 5px;
      position: absolute;
      bottom: -3px;
      z-index: -1;
    }
  }


  &--disabled {
    color: $gray_d1;
    cursor: not-allowed;
  }

  &--flat {
    box-shadow: none;
    text-transform: none;
  }

  &--extra-small {
    font-size: 0.75rem;
    padding: 8px 16px;
  }

  &--small {
    font-size: 0.875rem;
    padding: 10px 16px;
    height: 45px;
  }

  &--medium {
    font-size: 1rem;
    padding: 11px 22px;
    height: 50px;
  }

  &--large {
    font-size: clamp(1.25rem, 2.5vw, 1.125rem);
    padding: 12px 24px;
    height: 55px;
  }

  &--CTA {
    font-size: clamp(1.25rem, 2.5vw, 1.5rem) !important;
    padding: 12px 24px;
    height: 70px;
    text-transform: uppercase;

    &.capitalize {
      text-transform: none;
    }
  }
  &--kjarabilar {
    width: fit-content;
    width: 101px;
    font-size: 0.875rem;
    text-align: center;
    box-shadow: none;
    border: 1px solid $bl_red;
    border-radius: 4px;
    color: $bl_red;
    font-weight: 700;
    padding: 0.656rem 1.031rem;
    height: unset;
    line-height: unset;
    height: 40px;

    &:hover {
      background-color: $bl_red;
      color: $white;
      box-shadow: none;
    }
  }
  &--bl-blue {
    background-color: $bl_blue;
    color: $white;
  }
}