@import "~bootstrap/scss/mixins";
@import "@styles/custom";

.car-comparison-table-wrapper{
  padding-left: 0;
  margin-left: calc(var(--bs-gutter-x)* 0.5);
}

.car-comparison-table {
  width: 100%;
  table-layout: fixed; 
  --table-cell-width: 14rem;
  --table-label-width: 8rem;
  --table-column-spacing: 1rem;

  @include media-breakpoint-up(md) {
    --table-cell-width: 16rem;
    --table-label-width: 12rem;
    --table-column-spacing: 2rem;
  }

  @include media-breakpoint-up(lg) {
    --table-cell-width: 20rem;
    --table-label-width: 20rem;
    --table-column-spacing: 5rem;
  }

  tr {
    &:hover {
      td {
        background-color: #f9fafa;
      }
    }
  }

  th,
  td {
    &:first-child {
      width: var(--table-label-width);
      position: sticky;
      left: 0;
      background-color: #fff; 
      z-index: 2;
    }

    &.transparent:first-child {
      background-color: transparent;
    }

    &:not(:first-child) {
      min-width: max-content;
      width: calc(var(--table-cell-width) + var(--table-column-spacing));
      padding-left: var(--table-column-spacing);
    }

  }

  tr.nohover:hover {
    td { 
      background: #fff !important; 
    }
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 3; /* Header above all */
    background-color: #fff;
  }


  &__header {
    color: $primary;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    text-align: right;
    padding: 0.5rem 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }

    a {
      color: inherit;
    }
  }

  &__image-row {
    text-align: right;
  }

  &__availability {
    padding: 0.25rem 1rem;
  }

  &__value-row {
    border-bottom: 1px solid rgba($gray_d2, 0.15);

  }

  &__label {
    text-align: left;
    padding: 0.5rem;
    position: sticky;
    left: 0;
    background-color: #fff; 
    z-index: 2; // Ensures it stays above other cells
  }

  &__category {
    color: $primary;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    text-align: left;
    border-bottom: 1px solid $secondary;
    padding-top: 2rem;
    width: 100%;
  }

  &__category-name {
    position: sticky;
    left: 0;
  }

  &__image {
    width: 100%;
    max-width: 18rem;
    height: auto;
    margin: 0 0 0 auto;
  }

  &__value {
    padding: 0.5rem 1rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }

  &__unit {
    font-size: 0.75em;
    margin-left: 0.25rem;
  }
}

