.content_page_nav {
  border-bottom: 1px solid $gray_l1;
  flex-wrap: nowrap;
  position: relative;
  padding-bottom: 16px;
  margin: 26px 0 60px;
  overflow: auto;
  overflow-y: hidden;

  .nav-item {
    &.sticky {
      position: sticky;
      left: 0;
      z-index: 2;
    }
    .nav-link,
    span {
      padding: 0;
      font-size: 15px;
      line-height: 17px;
      font-weight: 500;
      color: $main-label;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      background-color: $white;
    }

    span {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 17px;
        width: 1px;
        background-color: $gray_l1;
        right: -14px;
        top: 1px;
      }

      &:hover,
      &.active {
        &::after {
          content: "";
          position: absolute;
          background-color: $bl_lightblue;
          height: 4px;
          width: auto;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 12.5px;
        }
      }
    }

    .nav-link {
      margin: 0 0 0 28px;

      &:hover,
      &.active {
        &::after {
          content: "";
          position: absolute;
          background-color: $bl_lightblue;
          height: 4px;
          width: auto;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }
      }
    }
  }
  .nav-link,
  span {
    padding: 0;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    color: $main-label;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    background-color: $white;
  }

  span {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 17px;
      width: 1px;
      background-color: $gray_l1;
      right: -14px;
      top: 1px;
    }

    &:hover,
    &.active {
      &::after {
        content: "";
        position: absolute;
        background-color: $bl_lightblue;
        height: 4px;
        width: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 12.5px;
      }
    }
  }

  .nav-link {
    margin: 0 0 0 28px;

    &:hover,
    &.active {
      &::after {
        content: "";
        position: absolute;
        background-color: $bl_lightblue;
        height: 4px;
        width: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .content_page_nav {
    .nav-item {
      span {
        &::before {
          right: -8px;
          height: 16px;
        }
      }

      .nav-link,
      span {
        font-size: 14px;
      }

      .nav-link {
        margin: 0 0 0 17px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .content_page_nav {
    .nav-item {
      span {
        padding: 11px 11px 25px 0px;
        &::before {
          // right: -5.5px;
          z-index: 2;
          top: 11px;
          height: 14px;
          right: 3.5px;
        }
      }

      .nav-link,
      span {
        font-size: 12px;
      }

      .nav-link {
        margin: 0 0 0 11px;
      }
    }

    .nav-link,
    span {
      font-size: 12px;
    }

    .nav-link {
      margin: 0 0 0 11px;
    }
  }
}
