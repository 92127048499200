.video-block {
  .responsive-object {
    > div {
      position: relative;
      width: 100%;
      display: block;
      height: 0;
      margin: auto;
      padding: 0% 0% 56.25%;

      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  .text-container {
    .title {
      font-size: $h2-font-size;
      margin-bottom: 25px;
    }

    .pre-title {
      font-size: 16px;
      font-weight: $font-weight-bold;
      color: $bl-lightblue;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}
