@import "~bootstrap/scss/mixins";
@import "@styles/custom";

.car-card {
  width: 100%;
  max-width: 32rem;
  height: 100%;
  padding: 1rem;
  border: none;
  background: #fff;
  border-radius: 8px;
  transition: box-shadow 0.15s ease-in-out;

  @include media-breakpoint-up(sm) {
    padding: 2.25rem;
  }

  &:hover {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.09);
  }

  &__link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }

  &__badge-container {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: inline;
      margin: -16px -8px 0 -8px;
      .badge-wrapper {
        margin-left: 0px !important;
        max-width: 350px;
        overflow-x: clip;
      }
    }
  }

  &__img {
    width: 100%;
    max-width: 640px;
    height: auto;
    max-height: 320px;
    margin: 0 auto;
    object-fit: contain;
  }

  &__title {
    color: $primary;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin: auto 0 0 0;
    padding: 12px 0;
  }

  ul.fuel-types {
    color: $gray_d2;
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    width: max-content;
    margin: 0;
    padding: 0;

    li + li::before {
      content: "•";
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1;
      margin: 0 8px;
    }

    svg {
      margin-right: 5px;
    }
  }

  &__info-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap-reverse;
    gap: 1rem;

    @include media-breakpoint-up(sm) {
      margin-top: 1.5rem;
    }
  }

  &__price-container {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
  }

  &__price-label {
    color: $bl_lightblue;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  &__price {
    color: $primary;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
  }

  &__kjarabill-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    & > * {
      color: $tertiary;
      margin: 0;
    }
  }

  &__price-before {
    color: $primary;
    font-size: 0.75rem;
    font-weight: bold;
    text-decoration: line-through;
    text-decoration-color: $secondary;
    margin-left: auto;
  }

  &__info-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    li {
      display: flex;
      flex-direction: column;
      width: max-content;
      color: $gray_d2;
    }

    li:not(:first-child) {
      border-left: 1px solid $gray_l3;
      padding-left: 1rem;
    }
  }

  &__info-label {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  &__info-value {
    font-size: 1.25rem;
  }

  &__value-unit {
    font-size: 0.875rem;
  }
}

@include media-breakpoint-down(md) {
  .car-card {
    &__title {
      margin: 0;
    }
  }
}
