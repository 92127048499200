.image-cards-block {
  .gray-bg {
    padding: 80px 0px 114px;

    .image-card-wrapper {
      &:nth-of-type(odd) {
        position: relative;
        top: 50px;
      }

      .image-card-container {
        .text-container {
          background-color: $white;
          padding: 22px 30px;

          .card-title {
            font-size: $h6-font-size;
            font-weight: $font-weight-bold;
            color: $bl_blue;
          }

          .card-paragraph {
            font-size: $paragraph-standard;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .image-cards-block {
    .gray-bg {
      .image-card-wrapper {
        &:nth-of-type(odd) {
          position: relative;
          top: unset;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .image-cards-block {
    padding-bottom: 90px;
    padding-top: 80px;

    .image-card-wrapper {
      .image-card-container {
        margin-bottom: 50px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .image-cards-block {
    .gray-bg {
      .image-card-wrapper {
        .image-card-container {
          .text-container {
            padding: 20px;
          }
        }
      }
    }
  }
}
