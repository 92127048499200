header {
  .navbar {
    margin-top: 40px;
    box-shadow:
      0 1px 5px 0 rgba(0, 0, 0, 0.05),
      0 10px 80px 0 rgba(0, 0, 0, 0.06);
    color: $white;
    height: 80px;
    margin-bottom: 55px;

    .navbar-brand {
      margin-right: 40px;
      width: 125px;

    }


    ul.nav.navbar-nav {
      li {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 30px;
        white-space: nowrap;

        &.active {
          a {
            color: $bl_lightblue;
        
          }
        }

        &.ancestor {
          a {
            color: $bl_lightblue;
          }
        }

        a {
          color: $main_label;
          transition: color 0.3s ease-in-out;
        }

        &:hover {
          a {
            color: $bl_blue;
          }
        }
      }
    }
    .userbar-container {
      .appointment-btn {
        font-size: 15px;
        padding: 10px 22px 10px 19px;
        svg {
          margin-right: 3px;          
        }
      }
    }

    .navbar-toggler {
      border: none;
      z-index: 1100;

      &:focus {
        box-shadow: none;
      }

      font-size: 20px;
      color: $main_label;

      .hamburger-icon {
        display: block;
      }

      .close-icon {
        display: none;
      }

      &.toggled .hamburger-icon {
        display: none;
      }

      &.toggled .close-icon {
        display: block;
        position: relative;
        top: -7px;
        right: -4px;
      }

      &.toggled {
        &::before {
          content: "";
          position: absolute;
          height: 56px;
          width: 56px;
          // background-color: $bl_lightblue;
          right: 0;
          top: 0;
        }
      }
    }
  }

  .navbar-collapse {
    transition: none !important;
    height: 0;

    &.show {
      height: 100vh;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $bl_blue;
      z-index: 1000;

      &.collapsing {
        height: 0;
        overflow: hidden;
      }

      .nav-content {
        position: relative;
        padding: 28% 45px 40px;

        .mobile-menu {
          ul.nav.navbar-nav {
            margin-bottom: 50px;

            .nav-item {
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
              padding: 18px 0;

              &:first-child {
                border-top: 1px solid rgba(255, 255, 255, 0.2);
              }

              a {
                color: $white;
                font-size: 18px;
              }

              &.active {
                a {
                }
              }
            }
          }

          .userbar-container-mobile {
            .userbar {
              .appointment-btn {
                margin-bottom: 30px;
                font-size: 18px;
                width: 100%;
              }
            }
          }

          .menu-search {
            background-color: $white;
            padding: 15px;
            border-radius: 3px;

            svg {
              margin-right: 20px;
            }

            input {
              outline: 0;

              &::placeholder {
                color: $placeholder;
                font-size: 14px;
                font-weight: $font-weight-medium;
              }
            }
          }
        }

        .nav-expand-content {
          display: none;
          opacity: 0;
          transition: opacity 0.3s ease;
          padding: 28% 45px 40px;
        }

        .nav-item.active .nav-expand-content {
          display: block;
          opacity: 1;
          position: absolute;
          background-color: $bl_blue;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          // This is not ideal, but for the release this will have to do.
          // The mobile navigation in general might need more work.
          z-index: 1;

          .nav-back-link {
            cursor: pointer;

            .back-icon {
              display: inline;
              padding-left: 0;
              margin-left: 0;
              position: relative;
              left: -20px;
            }
          }

          .parent-link {
            font-size: 18px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding-bottom: 21px;
          }

          .child-link {
            text-transform: none;
            font-size: 18px;
            font-weight: $font-weight-normal;
            padding: 21px 0 18px;

            &.active {
              font-weight: $font-weight-medium;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  header {
    height: 72px;
    margin-bottom: 1rem;
    padding: 0 0;

    .navbar {
      margin-top: 0;
      padding-top: 0;
      height: 67px;
      background-color: $white;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      transition: top 0.3s;
      z-index: 100;

      &.up {
        transform: translateY(-100%);
        transition: transform 0.2s linear;
      }

      &.down {
        transform: translateY(0);
        transition: transform 0.2s linear;
      }

      .navbar-wrapper {
        padding-right: 9px;

        .navbar-brand {
          padding-top: 0;
          z-index: 1010;
          width: 105px;
        }

        .userbar-container {
          position: absolute;
          right: 48px;

          .userbar {
            .appointment-btn,
            .search-btn {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  header {
    .navbar {
      .navbar-wrapper {
        .navbar-brand {
          margin-right: 20px;
        }

        ul.nav.navbar-nav li {
          margin-right: 16px;
        }
      }
    }
  }
}

@include media-breakpoint-down(1920px) {
  header {
    padding: 0 40px;
  }
}

@include media-breakpoint-down(md) {
  header {
    .navbar-collapse {
      &.show {
        .nav-content {
          padding: 28% 45px 100px;
        }
      }
    }
  }
}
