.staff-list-block {
  .gray-bg {
    border-radius: 4px;
    padding-top: 90px;
    padding-bottom: 70px;

    h3.title {
      margin-bottom: 60px;
    }

    .staff {
      margin-bottom: 16px;
      padding: 16px 25px;
      color: #62676c;
      font-size: clamp(14px, 1.5vw, 16px);
      font-weight: $font-weight-medium;
      background-color: $white;
      border-radius: 3px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);

      .name,
      .department,
      .position-title,
      .email {
        margin-top: auto;
        margin-bottom: auto;
      }

      .name {
        color: $bl_blue;
        font-weight: $font-weight-bold;
      }

      .email {
        color: $bl_lightblue;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .staff-list-block {
    .gray-bg {
      .staff {
        padding: 16px 8px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .staff-list-block {
    .gray-bg {
      padding-top: 70px;
      padding-bottom: 50px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .staff-list-block {
    .gray-bg {
      .staff {
        .name {
          flex-basis: unset;
          font-size: 16px !important;
          margin-bottom: 4px;
        }
      }
    }
  }
}