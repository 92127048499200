.card-link-list-block {
  .gray-bg {
    border-radius: 16px;
    padding-top: 72px;
    padding-bottom: 82px;

    .card {
      border: 1px solid $gray_l1;
      border-radius: 4px;
      background-color: $white;
      margin-bottom: 40px;
      padding: 36px 23%;
      height: calc(100% - 40px);
      transition: box-shadow 0.3s ease-in-out;
      .icon-container {
        height: 67px;
        .card-icon {
          margin-bottom: 20px;
        }
      }
      .card-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: $font-weight-bold;
      }
      .card-text {
        font-size: 15px;
        line-height: 24px;
      }
      &:hover {
        box-shadow: $card-shadow;
      }
    }
  }
}
@include media-breakpoint-down(xl) {
  .card-link-list-block {
    .gray-bg {
      padding-top: 72px;
      padding-bottom: 82px;
      .card {
        margin-bottom: 35px;
        padding: 36px 10%;
        height: calc(100% - 35px);
        .card-icon {
          margin-bottom: 20px;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .card-link-list-block {
    .gray-bg {
      padding-top: 58px;
      padding-bottom: 62px;
      .card {
        margin-bottom: 35px;
        padding: 36px 10%;
        height: calc(100% - 35px);
        .card-icon {
          margin-bottom: 20px;
        }
      }
    }
  }
}
@include media-breakpoint-down(xxl) {

  .card-link-list-block {
    .gray-bg {
      border-radius: 0;
    }
  }
}
