.userbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  .button {
    white-space: nowrap;
  }
}
@include media-breakpoint-down(xl) {
  .userbar {
    .button {
      &--small {
        padding: 10px 11px;
      }
    }
  }
}
