.news-page {
  margin-bottom: 80px;

  .page-title-container {
    a {
      svg {
        transform: rotate(180deg);
        position: relative;
        left: -17px;
        top: -2px;
        transition: left 0.3s ease-in-out, fill 0.3s ease-in-out;
      }

      &:hover {
        svg {
          left: -22px;
          g g {
            fill: $bl_blue;
          }
        }
      }
    }
  }

  .header-container {
    .title-container {
      .timestamp {
        font-weight: $font-weight-bold;
        color: $bl-lightblue;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      .excerpt {
        font-size: clamp(18px, 3vw, 20px);
        font-weight: $font-weight-medium;
      }

      h1 {
        font-weight: $font-weight-bold;
        margin-bottom: 70px;
        color: $bl_blue;
        &.news-title {
          margin-bottom: 50px;
          font-size: clamp(36px, 5vw, 64px);
        }
      }
    }
  }

  .more-btn {
    .button {
      white-space: nowrap;
    }
  }

  .mb-80 {
    margin-bottom: 80px;
  }
}

@media (max-width: 1576px) {
  .news-page {
    .page-title-container {
      a {
        svg {
          left: 2px;
          margin-right: 17px;
        }

        &:hover {
          svg {
            left: -5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .news-page {
    .page-title-container {
      h1 .news-title {
        margin-bottom: 40px;
      }

      a {
        svg {
          margin-right: 12px;
        }

        &:hover {
          svg {
            left: -5px;
          }
        }
      }
    }
  }
}
