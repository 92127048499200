.car-carousel {
  --carousel-item-width: 28rem;
  --carousel-item-height: 34rem;
  --carousel-list-padding: 2rem;
  --carousel-item-spacing: 2rem;

  @include media-breakpoint-up(md) {
    --carousel-item-width: 30rem;
  }

  width: 100%;
  height: calc(var(--carousel-item-height) + var(--carousel-list-padding) * 2);

  &__scroller {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    & > div {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &__list {
    padding: var(--carousel-list-padding) 0;
  }

  &__item {
    width: var(--carousel-item-width);
    margin-right: var(--carousel-item-spacing);
    scroll-snap-align: start;
    scroll-margin: var(--carousel-item-spacing);

    @include media-breakpoint-up(md) {
      scroll-margin: 0;
    }

    &:first-child {
      margin-left: var(--carousel-item-spacing);

      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }
  }

  &__footer {
    width: var(--carousel-item-width);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .snap-center {
    scroll-snap-align: center;
  }
}
 @include media-breakpoint-down(md) {
.car-carousel {

 --carousel-item-width: 22rem;
}

 }